





















































import Vue from "vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { InstituteType } from "@/interfaces/modules/institute/syllabi_comparison";
import SelectDegree from "@/components/modules/institute/SelectNationalDegree.vue";
import { NationalGlobalComparisonChoice } from "@/interfaces/modules/institute/national_global_comparison_choice";
import { Institutes } from "@/interfaces/data_objects/institutes";
import { Programs } from "@/interfaces/data_objects/programs";
import { mapActions, mapMutations } from "vuex";
import {
  COMPARISON_OTHER_SKILLS_LOADING,
  GLOBAL_DEGREE_LOADING,
  INSTITUTE_STATE_LOADING,
  NATIONAL_DEGREE_LOADING,
  NATIONAL_GLOBAL_COMPARISON,
  SELECTED_GLOBAL_INSTITUTE,
  SELECTED_NATIONAL_INSTITUTE,
  UPLOAD_GLOBAL_DEGREE,
  UPLOAD_NATIONAL_DEGREE
} from "@/store/modules/institute/constants";

export default Vue.extend({
  name: "NationalGlobalComparisonChoice",
  data(): NationalGlobalComparisonChoice {
    return {
      any_file_selected: false,
      national_institute_data: {
        national_institute: null,
        national_institute_program: null,
        selected_national_degree_file: null,
        selected_national_degree_content: ""
      },
      global_institute_data: {
        global_institute: null,
        global_institute_program: null,
        selected_global_degree_file: null,
        selected_global_degree_content: ""
      }
    };
  },
  computed: {
    InstituteType() {
      return InstituteType;
    }
  },
  components: { SelectDegree, GoBackHeader },
  methods: {
    ...mapActions("institute", {
      national_global_comparison: NATIONAL_GLOBAL_COMPARISON,
      upload_national_degree: UPLOAD_NATIONAL_DEGREE,
      upload_global_degree: UPLOAD_GLOBAL_DEGREE
    }),
    ...mapMutations("institute", {
      set_institute_loading: INSTITUTE_STATE_LOADING,
      set_national_degree_loading: NATIONAL_DEGREE_LOADING,
      set_national_institute: SELECTED_NATIONAL_INSTITUTE,
      set_global_institute: SELECTED_GLOBAL_INSTITUTE,
      set_comparison_other_skills_loading: COMPARISON_OTHER_SKILLS_LOADING,
      set_global_degree_loading: GLOBAL_DEGREE_LOADING
    }),
    // National degree functions
    update_national_selected_institute(value: Institutes | null) {
      this.national_institute_data.national_institute = value;
    },
    update_national_selected_program(value: Programs | null) {
      this.national_institute_data.national_institute_program = value;
    },
    update_national_selected_degree_file(value: File | null) {
      this.national_institute_data.selected_national_degree_file = value;
      this.any_file_selected = !!value;
    },
    update_national_selected_degree_content(value: string) {
      this.national_institute_data.selected_national_degree_content = value;
    },
    update_global_selected_institute(value: Institutes | null) {
      this.global_institute_data.global_institute = value;
    },
    update_global_selected_program(value: Programs | null) {
      this.global_institute_data.global_institute_program = value;
    },
    update_global_selected_degree_file(value: File | null) {
      this.global_institute_data.selected_global_degree_file = value;
      this.any_file_selected = !!value;
    },
    update_global_selected_degree_content(value: string) {
      this.global_institute_data.selected_global_degree_content = value;
    },
    async submit_form() {
      await this.set_national_degree_loading(true);
      await this.set_comparison_other_skills_loading(true);
      await this.set_global_degree_loading(true);
      // If user selected national degree or paste content
      if (
        this.national_institute_data.selected_national_degree_content ||
        this.national_institute_data.selected_national_degree_file
      ) {
        const national_skills_extracted =
          this.process_institute_custom_selection("national");
        // If failed to fetch national degree extracted skills
        if (!national_skills_extracted) return;
      } else {
        const national_skills_extracted =
          this.process_institute_existing_selection("national");
        // If failed to fetch national degree extracted skills
        if (!national_skills_extracted) return;
      }
      await this.set_national_institute(this.national_institute_data);
      await this.set_global_institute(this.global_institute_data);
      await this.$router.push("/institute/comparison/national-global/result");
      // Extracting global degree skills
      // If user selected global degree or paste content
      // if (
      //   this.global_institute_data.selected_global_degree_content ||
      //   this.global_institute_data.selected_global_degree_file
      // ) {
      //   const global_skills_extracted =
      //     this.process_institute_custom_selection("global");
      //   // If failed to fetch national degree extracted skills
      //   if (!global_skills_extracted) return;
      // } else {
      //   const global_skills_extracted =
      //     this.process_institute_existing_selection("global");
      //   // If failed to fetch national degree extracted skills
      //   if (!global_skills_extracted) return;
      // }
    },
    /**
     * Function to extract national degree skills
     * if degree file is selected
     * or degree content is selected
     */
    async process_institute_custom_selection(
      type = "national"
    ): Promise<boolean> {
      const _payload = new FormData();
      if (type === "national") {
        // If national degree file is uploaded
        if (
          this.national_institute_data.selected_national_degree_file &&
          this.national_institute_data.national_institute
        ) {
          // Degree file
          const file =
            this.national_institute_data.selected_national_degree_file;
          // Institute id
          const institute_id =
            this.national_institute_data.national_institute.id.toString();
          _payload.append("program_file", file);
          _payload.append("type", "file");
          _payload.append("ref_institute_id", institute_id);
          _payload.append("local_type", "file");
        }
        // If national degree content is uploaded
        else if (
          this.national_institute_data.selected_national_degree_content &&
          this.national_institute_data.national_institute
        ) {
          // Institute id
          const institute_id =
            this.national_institute_data.national_institute.id.toString();
          const degree_content =
            this.national_institute_data.selected_national_degree_content;
          _payload.append("degree_content", JSON.stringify(degree_content));
          _payload.append("type", "text");
          _payload.append("ref_institute_id", institute_id);
          _payload.append("local_type", "file");
        }
        return await this.upload_national_degree(_payload);
      } else {
        // If global degree file is uploaded
        if (
          this.global_institute_data.selected_global_degree_file &&
          this.global_institute_data.global_institute
        ) {
          // Degree file
          const file = this.global_institute_data.selected_global_degree_file;
          // Institute id
          const institute_id =
            this.global_institute_data.global_institute.id.toString();
          _payload.append("program_file", file);
          _payload.append("type", "file");
          _payload.append("ref_institute_id", institute_id);
          _payload.append("local_type", "file");
        }
        // If global degree content is uploaded
        else if (
          this.global_institute_data.selected_global_degree_content &&
          this.global_institute_data.global_institute
        ) {
          // Institute id
          const institute_id =
            this.global_institute_data.global_institute.id.toString();
          const degree_content =
            this.global_institute_data.selected_global_degree_content;
          _payload.append("degree_content", JSON.stringify(degree_content));
          _payload.append("type", "text");
          _payload.append("ref_institute_id", institute_id);
          _payload.append("local_type", "file");
        }
        return await this.upload_global_degree(_payload);
      }
    },
    /**
     * Function to extract national degree skills
     * if user select institute & program
     */
    async process_institute_existing_selection(
      type = "national"
    ): Promise<boolean> {
      const _payload = new FormData();
      if (type === "national") {
        if (
          this.national_institute_data.national_institute &&
          this.national_institute_data.national_institute_program
        ) {
          const institute_id =
            this.national_institute_data.national_institute.id;
          const program_id =
            this.national_institute_data.national_institute_program.id;
          _payload.append("ref_institute_id", institute_id.toString());
          _payload.append("local_type", "existing");
          _payload.append("ref_program_id", program_id.toString());
        }
        return await this.upload_national_degree(_payload);
      } else {
        if (
          this.global_institute_data.global_institute &&
          this.global_institute_data.global_institute_program
        ) {
          const institute_id = this.global_institute_data.global_institute.id;
          const program_id =
            this.global_institute_data.global_institute_program.id;
          _payload.append("ref_institute_id", institute_id.toString());
          _payload.append("local_type", "existing");
          _payload.append("ref_program_id", program_id.toString());
        }
        return await this.upload_global_degree(_payload);
      }
    }
  }
});
